@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    * {
        overscroll-behavior: none !important;
        -webkit-overflow-scrolling: auto !important;
    }

    .ios .overscroll {
        -webkit-overflow-scrolling: auto !important;
    }

    .input {
        @apply w-full transition-colors px-4 py-2 rounded-lg border focus:ring-0 focus:ring-transparent ring-0;
    }
}